import { createTheme } from '@mantine/core';

// ref: https://mantine.dev/theming/theme-object/
export const theme = createTheme({
  /** Put your mantine theme override here */

  // ref on using system vs web fonts: https://iloveseo.com/seo/system-fonts-vs-web-fonts-why-does-the-difference-matter/
  // https://systemfontstack.com/
  
  // fontFamily: "Roboto, sans-serif",
  // fontFamilyMonospace: 'Monaco, Courier, monospace',
  // headings: { fontFamily: 'Montserrat, sans-serif' },

  colors: {
    'persian-indigo': ['#5f04d7', '#5f04d7', '#5f04d7', '#5f04d7', '#5f04d7', '#5f04d7', '#5f04d7', '#36007D', '#36007D', '#36007D'],
    'russian-violet': ['#280A46', '#280A46', '#280A46', '#280A46', '#280A46', '#280A46', '#280A46', '#280A46', '#280A46', '#280A46'],
  },

  primaryColor:"violet",
  primaryShade: 6,

});
