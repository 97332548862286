import { OverlayLoader } from '@/components/OverlayLoader';
import { Suspense, lazy } from 'react';
import { RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { IconDatabaseExport } from '@tabler/icons-react';

// import HomePage from '@/pages/Home/Home';
// import  MobileDesktopDiscrepancy  from '@/pages/MobileDesktopDiscrepancy/MobileDesktopDiscrepancy';
// import  Demo  from "@/pages/Demo/Demo"
// import  Demo2  from '@/pages/Demo2/Demo2';

// Use React.lazy for code splitting
const HomePage = lazy(() => import('@/pages/Home/Home'));
const MobileDesktopDiscrepancy = lazy(() => import('@/pages/MobileDesktopDiscrepancy/MobileDesktopDiscrepancy'));
const SiteSimilaritySearch = lazy(() => import('@/pages/SiteSimilaritySearch/SiteSimilaritySearch'));
const ImageOptimization = lazy(() => import('@/pages/ImageOptimization/ImageOptimization'));
const PPTXGenerator = lazy(() => import('@/pages/PPTXGenerator/PPTXGenerator'));
const Demo = lazy(() => import('@/pages/Demo/Demo'));
const Demo2 = lazy(() => import('@/pages/Demo2/Demo2'));

type CustomRouteObject = RouteObject & {
  name?: string;
  icon?:JSX.Element;
  children?:CustomRouteObject[];
}

export const PPTX_CHILD_ROUTES:CustomRouteObject[] = [
  {
    path:"/pptx-generator",
    name:"Generator",
    icon:(<IconDatabaseExport/>),
    element: (
      <div>PPTX Generator HOME PAGE</div>
    ),
  },
  // {
  //   path:"/pptx-generator/transform",
  //   name:"Transform",
  //   icon:(<IconFilterCog/>),
  //   element: (
  //     <Transform/>
  //   ),
  // },
  // {
  //   path:"/pptx-generator/analyze",
  //   name:"Analyze",
  //   icon:(<IconChartHistogram/>),
  //   element: (
  //     <Analyze/>
  //   ),
  // },
  // {
  //   path:"/pptx-generator/visualize",

  //   name:"Visualize",
  //   icon:(<IconPresentationAnalytics/>),
  //   element: (
  //     <Visualize/>
  //   ),
  // },
  // {
  //   path:"/pptx-generator/static-analysis",
  //   name:"Code",
  //   icon:(<IconCode/>),
  //   element: (
  //     <StaticAnalysis/>
  //   ),
  // },
  // {
  //   path:"/pptx-generator/admin",
  //   name:"Admin",
  //   icon:(<IconShieldLock/>),
  //   element: (
  //     <Admin/>
  //   ),
  // },
];

export const ROUTES = [
  {
    path: '/',
    name:"Home",
    element: (
      <Suspense fallback={<OverlayLoader/>}>
        <HomePage />
      </Suspense>
    ),
  },
  {
    path:"/mobd",
    name:"Mobile Discrepancy",
    element: (
      <Suspense fallback={<OverlayLoader/>}>
        <MobileDesktopDiscrepancy />
      </Suspense>
    ),
  },
  {
    path:"/site-similarity-search",
    name:"Site Similarity Search",
    element: (
      <Suspense fallback={<OverlayLoader/>}>
        <SiteSimilaritySearch />
      </Suspense>
    ),
  },
  {
    path:"/image-optimization",
    name:"Image Optimization",
    element: (
      <Suspense fallback={<OverlayLoader/>}>
        <ImageOptimization />
      </Suspense>
    ),
  },
  {
    path:"/pptx-generator",
    name:"PPTX Generator",
    element: (
      <Suspense fallback={<OverlayLoader/>}>
        <PPTXGenerator />
      </Suspense>
    ),
    children:PPTX_CHILD_ROUTES,
  },
  {
    path:"/demo",
    name:"Demo",
    element: (
      <Suspense fallback={<OverlayLoader/>}>
        <Demo />
      </Suspense>
    ),
  },
  {
    path:"/demo2",
    name:"Demo 2",
    element: (
      <Suspense fallback={<OverlayLoader/>}>
        <Demo2 />
      </Suspense>
    ),
  },
];

const router = createBrowserRouter(ROUTES);

export function Router() {
  return <RouterProvider router={router} />;
}
